<template lang="pug">
  v-layout.coins(wrap)
    v-layout.coins_card(v-for="(coin, idx) in coins", :key="'n1_' + idx", align-center, @click="$emit('coinClick', coin.code)")
      CoinIcon(:name="coin.code")
      .coins_card_txt
        .code {{ coin.code }}
        div {{ coin.name }}
</template>

<script>
import CoinIcon from '@/components/CoinIcon';

export default {
  components: { CoinIcon },
  data() {
    return {
      coins: {
        1: {
          code: 'BTC',
          name: 'Bitcoin'
        },
        2: {
          code: 'ETH',
          name: 'Ethereum'
        },
        3: {
          code: 'BCH',
          name: 'Bitcoin Cash'
        },
        4: {
          code: 'USDT',
          name: 'Tether'
        },
        5: {
          code: 'KICK',
          name: 'KickToken'
        },
        6: {
          code: 'TRX',
          name: 'Tron'
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .coins {
    margin-top: 40px;
    max-width: 730px;

    .icon {
      max-width: 40px;
      max-height: 40px;
    }

    &_card {
      width: 100%;
      max-width: 190px;
      background-color: $white;
      margin-bottom: 16px;
      margin-right: 16px;
      border-radius: 2px;
      border: 1px solid $green;
      padding: 12px 20px;
      cursor: pointer;
      transition: all .3s $easing;

      &:hover { filter: brightness(.97); }

      &_txt {
        color: $green;
        font-size: 14px;
        font-weight: 700;
        margin-left: 20px;

        .code {
          color: $black2;
          font-size: 18px;
        }
      }
    }

    @media screen and (max-width: 600px) {
      .coins_card {
        max-width: 155px;
        padding-left: 20px !important;
      }
    }

    @media screen and (max-width: 365px) {
      .coins_card {
        max-width: unset;
        padding-left: 45px !important;
      }
    }
  }
</style>
