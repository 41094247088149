<template lang="pug">
  .visa-mastercard
    svg.visa(viewBox="0 0 50 17", fill="none")
      path(d="M14.5992 0.685853L10.4362 11.218L8.748 2.25896C8.55616 1.26138 7.7696 0.685853 6.90631 0.685853H0.095921L0 1.12709C1.40045 1.43404 2.99274 1.93283 3.95195 2.4508C4.54666 2.77693 4.70013 3.04551 4.91116 3.81288L8.09574 16.1675H12.3354L18.8197 0.685853H14.5992ZM20.5271 0.685853L17.2082 16.1483H21.2177L24.5366 0.666669H20.5271V0.685853ZM43.4906 4.86801L44.6992 10.6808H41.3803L43.4906 4.86801ZM42.8959 0.685853C42.1285 0.685853 41.4763 1.12709 41.1885 1.81772L35.1838 16.1675H39.3852L40.2293 13.8462H45.3706L45.8503 16.1675H49.5528L46.3107 0.685853H42.8959ZM25.6301 5.52027C25.6109 7.74564 27.6253 8.99261 29.1408 9.7408C30.6947 10.5082 31.2319 10.9878 31.2127 11.6592C31.1935 12.6952 29.9657 13.1556 28.8147 13.1748C26.8003 13.2131 25.6301 12.6376 24.7093 12.1964L23.9803 15.5728C24.9203 15.9948 26.6469 16.3785 28.431 16.3977C32.6323 16.3977 35.3949 14.3258 35.3949 11.1029C35.414 7.01664 29.7547 6.78643 29.7931 4.96393C29.8123 4.40759 30.3302 3.81288 31.5005 3.6594C32.076 3.58267 33.6491 3.52512 35.4524 4.35004L36.1622 1.06954C35.203 0.724222 33.956 0.378906 32.4213 0.378906C28.431 0.39809 25.6493 2.50835 25.6301 5.52027Z", :fill="'url(#' + gradientId + ')'")
      defs
        linearGradient(:id="gradientId", x1="-0.0001966", y1="8.38725", x2="49.5463", y2="8.38725", gradientUnits="userSpaceOnUse")
          stop(stop-color="#241F5D")
          stop(offset="1", stop-color="#034EA1")
    svg.mastercard(viewBox="0 0 33 20", fill="none")
      path(d="M21.5978 2.13818H11.9718V17.8602H21.5978V2.13818Z", fill="#FF5F00")
      path(d="M12.9556 10.0007C12.9544 8.48676 13.2976 6.99243 13.9592 5.63075C14.6208 4.26907 15.5835 3.07572 16.7744 2.14101C15.2994 0.981845 13.5281 0.261028 11.6628 0.0609429C9.79755 -0.139142 7.91363 0.189579 6.22636 1.00954C4.53909 1.82949 3.11655 3.1076 2.12131 4.69779C1.12607 6.28798 0.598289 8.12609 0.598289 10.002C0.598289 11.878 1.12607 13.7161 2.12131 15.3063C3.11655 16.8965 4.53909 18.1746 6.22636 18.9946C7.91363 19.8145 9.79755 20.1432 11.6628 19.9431C13.5281 19.7431 15.2994 19.0222 16.7744 17.8631C15.5831 16.9281 14.6202 15.7343 13.9586 14.3721C13.297 13.0099 12.954 11.515 12.9556 10.0007Z", fill="#EB001B")
      path(d="M32.0003 16.1969V15.8748H32.1392V15.8081H31.8087V15.8748H31.9392V16.1969H32.0003ZM32.6419 16.1969V15.8081H32.5419L32.4253 16.0858L32.3086 15.8081H32.2086V16.1969H32.2809V15.9025L32.3892 16.1553H32.4641L32.5725 15.9025V16.1969H32.6419Z", fill="#F79E1B")
      path(d="M32.9507 10.0007C32.9506 11.8768 32.4227 13.715 31.4273 15.3052C30.4319 16.8955 29.0092 18.1736 27.3217 18.9934C25.6342 19.8133 23.7501 20.1418 21.8847 19.9414C20.0194 19.741 18.248 19.0199 16.7732 17.8603C17.9636 16.9248 18.926 15.7311 19.5877 14.3694C20.2495 13.0076 20.5934 11.5133 20.5934 9.99929C20.5934 8.48525 20.2495 6.99099 19.5877 5.62923C18.926 4.26748 17.9636 3.0738 16.7732 2.13826C18.248 0.978732 20.0194 0.257564 21.8847 0.0571925C23.7501 -0.143179 25.6342 0.18533 27.3217 1.00517C29.0092 1.82501 30.4319 3.1031 31.4273 4.69334C32.4227 6.28358 32.9506 8.12181 32.9507 9.9979V10.0007Z", fill="#F79E1B")
</template>
<script>
export default {
  beforeMount() {
    this.gradientId = `f${(+new Date()).toString(16)}`;
  }
};
</script>
<style scoped lang="scss">
.visa-mastercard {
  display: flex;
  align-items: center;

  svg {
    &.visa {
      width: 50px;
      height: 17px;
      margin-right: 25px;
    }

    &.mastercard {
      width: 33px;
      height: 20px;
    }
  }
}
</style>
