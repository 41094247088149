<template lang="pug">
  img.coin-icon(
    v-if="name.length"
    :src="srcPath")
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  computed: {
    srcPath() {
      return `//kickex.com/static/coins/${this.name.toLowerCase()}.png`;
    }
  }
};
</script>

<style scoped lang="scss">
  .coin-icon {
    width: 40px;
  }
</style>
