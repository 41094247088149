<template lang="pug">
  v-layout.resend(column)
    v-flex
      p {{ $t('actions.resendConfirmationEmail') }}
    v-flex
      v-btn.no-active.resend_button(:disabled="countdownActive", @click="resendConfirmationEmail") {{ $t('actions.resend') }}
      //- .resend_text {{ $t('actions.resendConfirmationEmail') }} #[span.main_link(:class="{disabled: countdownActive}", @click="resendConfirmationEmail") Resend]
    v-flex.resend_success(v-if="successfulResend") {{ $t('messages.success.confirmationResend', { email }) }}
    v-flex.timer(v-if="countdownActive") {{ $t('messages.inform.timeLeftToRequestRestoreEmal') }}
      Countdown(:timer="fiveMinInMs", :timerStart="confirmationSentTimestamp", @expired="countdownActive = false")
</template>

<script>
import { mapState } from 'vuex';
import Countdown from '@/components/Countdown';

export default {
  components: { Countdown },
  data() {
    return {
      successfulResend: false,
      countdownActive: false,
      fiveMinInMs: 5 * 60 * 1000
    };
  },
  methods: {
    resendConfirmationEmail() {
      if (this.countdownActive) return;

      this.$store.dispatch('user/resendConfirmationEmail')
        .then(() => { this.successfulResend = true; });
    }
  },
  mounted() {
    if (this.confirmationSentTimestamp && Math.trunc(Date.now() - this.confirmationSentTimestamp) <= this.fiveMinInMs) {
      this.countdownActive = true;
    }
  },
  computed: {
    ...mapState('user', [
      'email',
      'confirmationSentTimestamp'
    ])
  },
  watch: {
    confirmationSentTimestamp(val) {
      if (val) this.countdownActive = true;
    }
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  .resend {
    max-width: 514px;
    margin: 30px auto;
    text-align: left;

    .resend_text {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .main_link {
      display: inline-block;
      text-decoration: underline !important;

      &.disabled {
        color: $disabled-link !important;
        text-decoration: none !important;
        cursor: initial;
      }
    }

    &_button {
      width: 204px;
      padding: 21px 0;
      height: 60px;
      background-color: $blue !important;
      color: $white !important;
      text-transform: none !important;
      border-radius: 2px;
      font-size: 18px;
      letter-spacing: 0.03em;
      font-weight: 600;
      line-height: 20px;
      text-align: center;

      &:hover { background-color: $secondary-blue-color; }
    }

    &_success,
    .timer {
      margin-top: 20px;
    }
  }

  ::v-deep .v-input--selection-controls { margin-top: 0 !important;}

</style>
