<template lang="pug">
  v-layout.verification__block(align-center, justify-center, row, wrap, align-content-center)
    v-flex.verification__header(xs12, sm12, md12, lg12, xl12) {{ $t('messages.inform.howToVerifyYourAccount') }}
    v-flex.verification__description(xs12, sm12, md12, lg12, xl12) {{ emailConfirmed ? $t('messages.inform.KYCProcessExplanation') : $t('messages.inform.KYCComfirmEmail') }}
    //- v-flex.verification__description.not_confirmed(v-if="!emailConfirmed", xs12, sm12, md12, lg12, xl12) {{ $t('messages.warn.confirmEmail') }}
    //- v-flex(xs12, sm12, md12, lg12, xl12)
      v-btn.no-active.verification__button(v-if="emailConfirmed", :disabled="!acceptedTerms", @click="$emit('accept')") {{ $t('actions.confirm') }}
      v-layout.verification__checkbox(v-if="emailConfirmed")
        v-checkbox(v-model="acceptedTerms")
        .verification__checkbox__label {{ $t('actions.agreeTo') }} #[router-link(to="/terms", target="_blank") {{ $t('common.termsAndConditions') }}] {{ $t('common.and') }} #[router-link(to="/privacy", target="_blank") {{ $t('pageTitles.privacyPolicy') }}].
</template>

<script>
export default {
  props: {
    emailConfirmed: { type: Boolean, required: true }
  }
};
</script>

<style scoped lang="scss">
  @import '../assets/scss/variables';

  .verification {
    &__block {
      max-width: 514px !important;
      text-align: left;
      margin: auto;
    }

    &__header {
      font-weight: bold;
      font-size: 35px;
      line-height: 40px;
      color: $sub-black;
    }

    &__description {
      margin-top: 23px;
      line-height: 32px;
      font-size: 20px;
      color: $sub-black;
    }

    &__checkbox {
      margin-top: 25px;

      &__label {
        font-size: 16px;
        color: $sub-black;
        font-weight: 600;
        margin: 10px 0 0 7px;
        line-height: 1.2;

        & > a {
          color: $blue;
          text-decoration: none;
        }
      }

      ::v-deep .v-input--selection-controls__input::before { border: 1px solid $sub-black !important; }
    }
  }

  ::v-deep .v-input--selection-controls { margin-top: 0 !important; }
</style>
