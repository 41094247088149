<template lang="pug">
  v-row.links(align="stretch", justify="space-between")
    v-col(v-for="(n, idx) in 4", :key="'n3_' + idx")
      v-layout.links_card(column, align-start, justify-space-between)
        div {{ $t(`thankYouPage.mainLinks.${n}.txt`) }}
        v-btn(@click="$emit('linkClick', mainLinks[n].url, mainLinks[n].id )") {{ $t(`thankYouPage.mainLinks.${n}.btn`) }}
</template>

<script>
export default {
  data() {
    return {
      mainLinks: {
        1: {
          url: process.env.VUE_APP_KICKREF_LK,
          id: 4
        },
        2: {
          url: `${process.env.VUE_APP_KICKEX}/${this.$i18n.locale}/markets`,
          id: 3
        },
        3: {
          url: `${process.env.VUE_APP_KICKEX}/${this.$i18n.locale}/contests`,
          id: 3
        },
        4: {
          url: `${process.env.VUE_APP_KICKEX}/${this.$i18n.locale}/trade`,
          id: 3
        }
      }
    };
  }
};
</script>

<style lang="scss">
  @import '../assets/scss/variables';

  .links {
    margin-top: 30px;

    &_card {
      height: 100%;
      font-weight: 500;

      & > div {
        max-width: 260px;
      }

      .v-btn {
        font-size: 14px !important;
        background-color: transparent !important;
        color: $green !important;
        border: 2px solid $green !important;
        padding: 10px 15px !important;
        min-width: 180px !important;
        margin-top: 22px;

        &::before { opacity: 0 !important; }
      }

      @media screen and (max-width: 760px) {
        & > div {
          font-size: 14px;
          max-width: 180px;
        }
      }
    }
  }
</style>
